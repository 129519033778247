import React from 'react';
import styled from 'styled-components';
import SublimeVideo from 'react-sublime-video'

const App = () => {
    const [playing, setPlaying] = React.useState()
    const [videoSrc, setVideoSrc] = React.useState('/frenchfry.mp4')
    const handleFullScreen = () => {
        const elem = document.querySelector('video')
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    return (
        <Container playing={playing}>
            <div className='main_wrap'>
                <div className='top_movement'>
                    <img className='background' src='/background.jpg' alt='Pizza hut fun background' />
                    <img className='circle' src='/spinner.png' alt='Pizza hut fun circle' />
                    <img className='logo' src='/logo.png' alt='Pizza hut fun logo' />
                </div>
                <div className='video_wrapper' onDoubleClick={handleFullScreen}>
                    <SublimeVideo src={videoSrc} onPlay={() => setPlaying(true)} onPause={() => setPlaying(false)} />
                </div>
                <div className='selection_wrap'>
                    <div open={videoSrc === '/frenchfry.mp4'} onClick={() => setVideoSrc('/frenchfry.mp4')}><img src='/frenchfry.jpg' /></div>
                    <div open={videoSrc === '/pizzaboy.mp4'} onClick={() => setVideoSrc('/pizzaboy.mp4')}><img src='/pizzaboy.jpg' /></div>
                    <div open={videoSrc === '/pizzagirl.mp4'} onClick={() => setVideoSrc('/pizzagirl.mp4')}><img src='/pizzagirl.jpg' /></div>
                    <div open={videoSrc === '/tomato.mp4'} onClick={() => setVideoSrc('/tomato.mp4')}><img src='/tomato.jpg' /></div>
                </div>
            </div>
        </Container>
    );
};

export default App;

const Container = styled.div`
    background:#221E20;
    width:100vw;
    height:100svh;
    display:flex;
    justify-content:center;
    .main_wrap{
        .top_movement{
            position:relative;
            img.background{
                width:100%;
                max-width:420px;
            }
            img.circle{
                position:absolute;
                width:100%;
                max-width:420px;
                z-index:1;
                left:0px;
                top:0px;
                scale:0.7;
                ${({ playing }) => playing && `animation: spin 2.5s linear 0s infinite normal;`};
            }
            img.logo{
                position:absolute;
                width:100%;
                max-width:420px;
                z-index:1;
                left:0px;
                top:0px;
                scale:0.3;
                ${({ playing }) => playing && `
                    animation: pop 1s linear 0s infinite normal;
                    -moz-animation: pop 1s linear 0s infinite normal;
                    -webkit-animation: pop 1s linear 0s infinite normal;
                `};
            }
        }
        .video_wrapper{
            position:relative;
            padding:15px;
            border:1px solid rgba(255,255,255,0.8);
            border-radius:4px;
            width:100%;
            max-width:420px;
            box-sizing:border-box;
            margin-top:-50px;
            .video-react-button{
                left:50%;
                top:50%;
                margin-left:-43px;
                margin-top:-20.5px;
            }
        }
        .selection_wrap{
            display:flex;
            position:relative;
            width:100%;
            max-width:420px;
            margin-top:10px;
            gap:10px;
            div{
                padding:10px;
                border:1px solid rgba(255,255,255,0.8);
                border-radius:4px;
                position:relative;
                cursor:pointer;
                &:before{
                    content:"";
                    display:block;
                    position:absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    background:rgba(255,255,255,0.67);
                    opacity:0;
                    transition:0.3s ease;
                }
                &[open]{
                    &:before{
                        opacity:1;
                    }
                }
                img{
                    width:100%;
                }
            }
        }
    }
    @keyframes spin{
        0% {
            -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn);
        }
        100% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
    @keyframes pop{
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        50% {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
`